/** Generated by FG **/
@font-face {
	font-family: "Arboria";
	src: url("../../fonts/Arboria-Bold/Arboria-Bold.eot");
	src: local("☺"),
		url("../../fonts/Arboria-Bold/Arboria-Bold.woff") format("woff"),
		url("../../fonts/Arboria-Bold/Arboria-Bold.ttf") format("truetype"),
		url("../../fonts/Arboria-Bold/Arboria-Bold.svg") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: block;
}

/** Generated by FG **/
@font-face {
	font-family: "Arboria";
	src: url("../../fonts/Arboria-Book/Arboria-Book.eot");
	src: local("☺"),
		url("../../fonts/Arboria-Book/Arboria-Book.woff") format("woff"),
		url("../../fonts/Arboria-Book/Arboria-Book.ttf") format("truetype"),
		url("../../fonts/Arboria-Book/Arboria-Book.svg") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Arboria";
	src: url("../../fonts/arboria-light/arboria-light-webfont.woff2")
			format("woff2"),
		url("../../fonts/arboria-light/arboria-light-webfont.woff")
			format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: block;
}

/** Generated by FG **/
@font-face {
	font-family: "Arboria";
	src: url("../../fonts/Arboria-Medium/Arboria-Medium.eot");
	src: local("☺"),
		url("../../fonts/Arboria-Medium/Arboria-Medium.woff") format("woff"),
		url("../../fonts/Arboria-Medium/Arboria-Medium.ttf") format("truetype"),
		url("../../fonts/Arboria-Medium/Arboria-Medium.svg") format("svg");
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

/** Generated by FG **/
@font-face {
	font-family: "pt serif";
	src: url("../../fonts/pt-serif-italic/pt-serif.italic.eot");
	src: local("☺"),
		url("../../fonts/pt-serif-italic/pt-serif.italic.woff") format("woff"),
		url("../../fonts/pt-serif-italic/pt-serif.italic.ttf")
			format("truetype"),
		url("../../fonts/pt-serif-italic/pt-serif.italic.svg") format("svg");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "RolexFont-S";
	src: url("../../fonts/rolex/Font.eot");
	src: url("../../fonts/rolex/Font.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/rolex/Font.woff2") format("woff2"),
		url("../../fonts/rolex/Font.woff") format("woff"),
		url("../../fonts/rolex/Font.ttf") format("truetype"),
		url("../../fonts/rolex/Font.svg#Font") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "RolexFont-S";
	src: url("../../fonts/rolexfont-regular-webS/Font.eot");
	src: url("../../fonts/rolexfont-regular-webS/Font.eot?#iefix")
			format("embedded-opentype"),
		url("../../fonts/rolexfont-regular-webS/Font.woff2") format("woff2"),
		url("../../fonts/rolexfont-regular-webS/Font.woff") format("woff"),
		url("../../fonts/rolexfont-regular-webS/Font.ttf") format("truetype"),
		url("../../fonts/rolexfont-regular-webS/Font.svg#Font") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Helvetica Now Text";
	src: url("../../fonts/helvetica-now-text/HelveticaNowTextW02-Regular.eot");
	src: url("../../fonts/helvetica-now-text/HelveticaNowTextW02-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("../../fonts/helvetica-now-text/HelveticaNowTextW02-Regular.woff2")
			format("woff2"),
		url("../../fonts/helvetica-now-text/HelveticaNowTextW02-Regular.woff")
			format("woff"),
		url("../../fonts/helvetica-now-text/HelveticaNowTextW02-Regular.ttf")
			format("truetype"),
		url("../../fonts/helvetica-now-text/HelveticaNowTextW02-Regular.svg#HelveticaNowTextW02-Regular")
			format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Helvetica Now Text";
	src: url("../../fonts/helveticanowtext-bold/HelveticaNowTextW02-Bold.eot");
	src: url("../../fonts/helveticanowtext-bold/HelveticaNowTextW02-Bold.eot?#iefix")
			format("embedded-opentype"),
		url("../../fonts/helveticanowtext-bold/HelveticaNowTextW02-Bold.woff2")
			format("woff2"),
		url("../../fonts/helveticanowtext-bold/HelveticaNowTextW02-Bold.woff")
			format("woff"),
		url("../../fonts/helveticanowtext-bold/HelveticaNowTextW02-Bold.ttf")
			format("truetype"),
		url("../../fonts/helveticanowtext-bold/HelveticaNowTextW02-Bold.svg#HelveticaNowTextW02-Bold")
			format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Helvetica Now Text";
	src: url("../../fonts/helveticanowtext-light/HelveticaNowTextW02-Light.eot");
	src: url("../../fonts/helveticanowtext-light/HelveticaNowTextW02-Light.eot?#iefix")
			format("embedded-opentype"),
		url("../../fonts/helveticanowtext-light/HelveticaNowTextW02-Light.woff2")
			format("woff2"),
		url("../../fonts/helveticanowtext-light/HelveticaNowTextW02-Light.woff")
			format("woff"),
		url("../../fonts/helveticanowtext-light/HelveticaNowTextW02-Light.ttf")
			format("truetype"),
		url("../../fonts/helveticanowtext-light/HelveticaNowTextW02-Light.svg#HelveticaNowTextW02-Light")
			format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
